exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artificial-intelligence-js": () => import("./../../../src/pages/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-artificial-intelligence-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-careers-contact-us-cv-js": () => import("./../../../src/pages/careers/contact-us-cv.js" /* webpackChunkName: "component---src-pages-careers-contact-us-cv-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-closed-vacancy-js": () => import("./../../../src/pages/closed-vacancy.js" /* webpackChunkName: "component---src-pages-closed-vacancy-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-dedicated-teams-js": () => import("./../../../src/pages/dedicated-teams.js" /* webpackChunkName: "component---src-pages-dedicated-teams-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-devops-js": () => import("./../../../src/pages/devops.js" /* webpackChunkName: "component---src-pages-devops-js" */),
  "component---src-pages-discovery-phase-js": () => import("./../../../src/pages/discovery-phase.js" /* webpackChunkName: "component---src-pages-discovery-phase-js" */),
  "component---src-pages-extended-reality-js": () => import("./../../../src/pages/extended-reality.js" /* webpackChunkName: "component---src-pages-extended-reality-js" */),
  "component---src-pages-fintech-js": () => import("./../../../src/pages/fintech.js" /* webpackChunkName: "component---src-pages-fintech-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-healthcare-js": () => import("./../../../src/pages/healthcare.js" /* webpackChunkName: "component---src-pages-healthcare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-service-providers-js": () => import("./../../../src/pages/internet-service-providers.js" /* webpackChunkName: "component---src-pages-internet-service-providers-js" */),
  "component---src-pages-mobile-development-js": () => import("./../../../src/pages/mobile-development.js" /* webpackChunkName: "component---src-pages-mobile-development-js" */),
  "component---src-pages-office-js": () => import("./../../../src/pages/office.js" /* webpackChunkName: "component---src-pages-office-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-development-js": () => import("./../../../src/pages/product-development.js" /* webpackChunkName: "component---src-pages-product-development-js" */),
  "component---src-pages-real-estate-js": () => import("./../../../src/pages/real-estate.js" /* webpackChunkName: "component---src-pages-real-estate-js" */),
  "component---src-pages-telecommunication-js": () => import("./../../../src/pages/telecommunication.js" /* webpackChunkName: "component---src-pages-telecommunication-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-vacancy-js": () => import("./../../../src/templates/vacancy.js" /* webpackChunkName: "component---src-templates-vacancy-js" */)
}

